import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/(app)": [4,[2]],
		"/auth/auth": [~14,[3]],
		"/auth/sign-out": [15,[3]],
		"/(app)/dashboard/reports": [~5,[2]],
		"/design": [~16],
		"/(app)/incident/user": [~6,[2]],
		"/(app)/indicators/configuration": [~7,[2]],
		"/(app)/indicators/devices": [~8,[2]],
		"/(app)/pdi": [9,[2]],
		"/(app)/pdi/configuration": [~11,[2]],
		"/(app)/pdi/[id]": [~10,[2]],
		"/(app)/record": [12,[2]],
		"/(app)/record/[id]": [~13,[2]],
		"/tv-offline": [~18],
		"/tv": [~17]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';